import React from "react";
import { css } from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-gtag";
import { Desktop, Tablet, HeightThresold } from "../../constants/responsive";
import Container from "../Container";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";

import Instagram from "../../svgs/social/instagram.svg";
import Youtube from "../../svgs/social/youtube.svg";
import LinkedIn from "../../svgs/social/linkedin.svg";
import HKSTP from "../../svgs/social/hkstp.svg";

import LanguageSwitcher from "../LanguageSwitcher/";
import InternalLink from "../InternalLink";

const Menu = ({ menuOpened, onClick }) => {
  const leftMenuList = [
    "about",
    "awards",
    "features",
    "robots",
    "blog",
    "contact",
  ];

  const socialMediaItems = [
    <OutboundLink
      href="https://www.hkstp.org/en/our-partner-companies/company-directory/company-details/?company=rice-robotics-limited"
      target="_blank"
      rel="noreferrer"
      key="hkstp"
      onClick={onClick}
    >
      <HKSTP
        css={css`
          width: 28px;
          ${Tablet} {
            width: 26px;
          }
          ${Desktop} {
            width: 30px;
          }
          svg,
          path {
            fill: #000;
          }
        `}
      />
    </OutboundLink>,
    <OutboundLink
      href="https://hk.linkedin.com/company/ricerobotics"
      target="_blank"
      rel="noreferrer"
      key="linkedIn"
      onClick={onClick}
    >
      <LinkedIn
        css={css`
          width: 32px;
          ${Tablet} {
            width: 30px;
          }
          ${Desktop} {
            width: 36px;
          }
        `}
      />
    </OutboundLink>,
    <OutboundLink
      href="https://www.instagram.com/riceroboticsofficial/"
      target="_blank"
      rel="noreferrer"
      key="instagram"
      onClick={onClick}
    >
      <Instagram
        key="instagram"
        css={css`
          width: 32px;
          ${Tablet} {
            width: 30px;
          }
          ${Desktop} {
            width: 36px;
          }
        `}
      />
    </OutboundLink>,
    <OutboundLink
      href="https://www.youtube.com/channel/UCz2jMaIlZZ2p8CbxQZa5U6g/"
      target="_blank"
      rel="noreferrer"
      key="youtube"
      onClick={onClick}
    >
      <Youtube
        css={css`
          width: 32px;
          ${Tablet} {
            width: 30px;
          }
          ${Desktop} {
            width: 36px;
          }
        `}
      />
    </OutboundLink>,
  ];

  return (
    <div
      className="menu"
      css={css`
        background: #fff;
        display: block;
        height: calc(100% - 70px);
        width: 100vw;
        position: fixed;
        top: ${menuOpened ? "70px" : "-200%"};
        right: 0px;
        bottom: 0px;
        left: 0px;

        z-index: 40;
        transition: all 0.5s ease;
        overflow-y: scroll;
      `}
    >
      <Container
        css={css`
          position: relative;
          margin: 0 auto;
          height: auto;
          display: flex;
          align-items: flex-start;
          padding-top: 30px;
          ${Tablet && HeightThresold} {
            padding-top: 120px;

            height: auto;
          }
          ${Desktop} {
            padding-top: 150px;
            width: 1200px;
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 40px;

            ${Tablet} {
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-end;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            {leftMenuList.map((item) => (
              <InternalLink
                to={`/#${item}`}
                key={item}
                onClick={onClick}
                css={css`
                  color: #212121;
                  &:hover {
                    color: #212121;
                  }
                  &:visited {
                    color: #212121;
                  }

                  text-align: center;
                  margin-bottom: 15px;
                  ${Tablet} {
                    text-align: left;
                  }
                  ${Desktop} {
                    text-align: left;
                    margin-bottom: 30px;
                  }
                `}
              >
                <h1
                  css={css`
                    text-align: center;
                    ${Tablet} {
                      font-size: 30px;
                      text-align: left;
                    }
                    ${Desktop} {
                      font-size: 45px;
                      text-align: left;
                    }
                  `}
                  key={item}
                >
                  <FormattedHTMLMessage id={`menu.` + item} />
                </h1>
              </InternalLink>
            ))}
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
            `}
          >
            <div
              css={css`
                margin-top: 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 20px;

                ${Tablet} {
                  flex-direction: column;
                  justify-content: flex-end;
                  margin-bottom: 30px;
                }
              `}
            >
              <h2
                css={css`
                  font-size: 22px;
                  font-weight: bold;
                  margin: 0;
                  margin-bottom: 15px;
                  text-align: center;

                  ${Tablet} {
                    font-size: 20px;
                    margin-bottom: 20px;
                    text-align: right;
                  }
                  ${Desktop} {
                    text-align: right;
                    font-size: 24px;
                    margin-bottom: 30px;
                  }
                `}
              >
                <OutboundLink
                  css={css`
                    color: #212121;
                    &:hover {
                      color: #212121;
                    }
                    &:visited {
                      color: #212121;
                    }
                  `}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdKMPcFBNudKwdTvdOeBdJHVOOdlFzYuov9AT3XpxQ0Ga-YEA/viewform"
                  target="_blank"
                  onClick={onClick}
                >
                  <FormattedHTMLMessage id="menu.join" key="join" />
                </OutboundLink>
              </h2>
              <div
                key="language"
                css={css`
                  color: #212121;
                  &:hover {
                    color: #212121;
                  }
                  &:visited {
                    color: #212121;
                  }
                  text-align: center;

                  ${Tablet} {
                    text-align: right;
                  }
                  ${Desktop} {
                    text-align: right;
                  }
                `}
              >
                <h2
                  css={css`
                    font-size: 22px;
                    font-weight: bold;

                    ${Tablet} {
                      font-size: 20px;
                    }
                    ${Desktop} {
                      font-size: 24px;
                    }
                  `}
                >
                  <LanguageSwitcher />
                </h2>
              </div>
            </div>
            <div
              css={css`
                width: 250px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                ${Tablet} {
                  margin-bottom: 20px;
                  width: 240px;
                }
                ${Desktop} {
                  margin-bottom: 35px;
                  width: 270px;
                }
              `}
            >
              {socialMediaItems.map((item) => item)}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Menu;
